import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import NeuroSkills from "../components/NeuroSkills"
// ...GatsbyImageSharpFluid

const NeuroSkillsPage = () => {
  return (
    <Layout>
      <h2>neuroskills page</h2>
    </Layout>
  )
}

export default NeuroSkillsPage
